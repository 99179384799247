<template>
  <div>
    <b-tabs content-class="p-3 bg-white">
      <b-tab title="Set Up" active>
        <b-row>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Name"
              placeholder="Name"
              type="text"
              isRequired
              v-model="random_reward.name"
              name="name"
              :v="$v.random_reward.name"
              :isValidate="$v.random_reward.name.$error"
          /></b-col>
          <b-col cols="12" md="6">
            <UploadFile
              textFloat="Image"
              placeholder="Please Choose File"
              format="video"
              :fileName="random_reward.image_url"
              v-model="random_reward.image_url"
              name="thumbnail"
              text="*Please upload only .png, .jpg image with ratio 1:1 less than 10 MB"
              isRequired
              accept="image/jpeg, image/png"
              v-on:onFileChange="(val) => onImageChange(val, 'image_url', 1)"
              :disabled="isDisable"
              :v="$v.random_reward.image_url"
              :isValidate="$v.random_reward.image_url.$error"
            />
            <!-- :v="$v.form.image_url" -->
            <PreviewBox
              v-on:delete="deleteImage('image_url')"
              :disabled="isDisable"
              :showPreviewType="0"
              :showPreview="random_reward.image_url"
              :isLoadingImage="isLoadingImage"
              :ratioType="1"
            />
          </b-col>

          <b-col>
            <TextEditorsTiny
              textFloat="Detail"
              placeholder="Detail"
              isRequired
              v-model="random_reward.description"
              :v="$v.random_reward.description"
              :isValidate="$v.random_reward.description.$error"
              @onDataChange="(val) => (random_reward.description = val)"
            />
          </b-col>
        </b-row>
        <hr />
        <InputText
          textFloat="Max Win Rate"
          placeholder="100"
          type="number"
          isRequired
          v-model="random_reward.max_win_rate"
          name="max_win_rate"
          class="w-4"
          :v="$v.random_reward.max_win_rate"
          :isValidate="$v.random_reward.max_win_rate.$error"
          maxLength="6"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0,6);'"
          @input="clearWinrate()"
        />

        <div>
          <div class="title-tabs">
            Reward Detail <span class="text-error">*</span>
          </div>
          <div class="pt-3 pb-3">
            <div class="text-black">
              <div>
                <div class="content-between">
                  <div>
                    <b-button
                      class="btn-select-branch w-100 w-sm-unset text-nowrap"
                      @click.prevent="openProductModal"
                      variant="custom-outline-primary"
                    >
                      Select Item
                      <font-awesome-icon
                        icon="chevron-right"
                        class="ft-14 ml-2"
                      />
                    </b-button>
                    <span class="ml-2">
                      Selected {{ reward_items.length }} Items
                    </span>
                  </div>
                  <div>
                    <b-button
                      class="w-sm-unset mr-2 text-nowrap"
                      @click.prevent="addReward(4)"
                      variant="custom-outline-primary"
                    >
                      <font-awesome-icon
                        icon="plus-square"
                        class="ft-14 mr-2"
                      />
                      Add Point Reward
                    </b-button>
                    <b-button
                      class="w-sm-unset text-nowrap"
                      @click.prevent="addReward(1)"
                      variant="custom-outline-primary"
                    >
                      <font-awesome-icon
                        icon="plus-square"
                        class="ft-14 mr-2"
                      />
                      Add No Reward
                    </b-button>
                  </div>
                </div>
                <b-table
                  :fields="fields"
                  :items="reward_items"
                  :busy.sync="isBusy"
                  :perPage="filter.RecordPerPage"
                  :currentPage="filter.PageNumber"
                  @context-changed="tableChange"
                  show-empty
                  striped
                  class="mt-3"
                  empty-text="No matching records found"
                  responsive
                >
                  <template #cell(id)="row">
                    <b-button
                      size="sm"
                      @click="row.toggleDetails"
                      class="mr-2"
                      variant="custom-link"
                    >
                      <font-awesome-icon
                        icon="chevron-down"
                        v-if="!row.detailsShowing"
                      />

                      <font-awesome-icon icon="chevron-up" v-else />
                    </b-button>
                  </template>
                  <template v-slot:cell(imageUrl)="data">
                    <div class="position-relative picture-text pic-table">
                      <div
                        v-if="data.item.imageUrl"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + data.item.imageUrl + ')',
                        }"
                      ></div>
                      <div
                        v-else-if="data.item.random_reward_item_type == 4"
                        class="square-box b-contain image point-image"
                      ></div>
                      <div
                        v-else-if="data.item.random_reward_item_type == 1"
                        class="square-box b-contain image no-reward-image"
                      ></div>
                      <div
                        v-else-if="data.item.image_url"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image':
                            'url(' + data.item.image_url + ')',
                        }"
                      ></div>
                      <div v-else>
                        <div
                          class="square-box b-contain image"
                          v-bind:style="{
                            'background-image': 'url(' + default_image + ')',
                          }"
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(quantity)="data">
                    <div class="position-relative">
                      <InputText
                        textFloat=""
                        v-model="data.item.quantity"
                        placeholder="0"
                        type="number"
                        :v="
                          $v.reward_items.$each[getIndexById(data.item.id)]
                            .quantity
                        "
                        :isValidate="
                          $v.reward_items.$each[getIndexById(data.item.id)]
                            .quantity.$error
                        "
                        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
                        class="mb-0"
                      />
                      <div class="text-left position-absolute">
                        <small class="text-error text-bold"
                          >Quantity 0 = Unlimit</small
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(win_rate)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.win_rate"
                      placeholder="0"
                      type="number"
                      class="mb-0"
                      decimalPoint="3"
                      :disabled="data.item.active == 0"
                      :isValidate="
                        $v.reward_items.$each[getIndexCondition(data.item)]
                          .win_rate.$error
                      "
                      @input="
                        (value, e) =>
                          handleWinrateInput(
                            value,
                            e,
                            getIndexCondition(data.item)
                          )
                      "
                    />
                    <div
                      class="text-left position-absolute"
                      v-if="
                        $v.reward_items.$each[getIndexCondition(data.item)]
                          .win_rate.$error
                      "
                    >
                      <small class="text-error text-bold"
                        >Please input value.</small
                      >
                    </div>
                  </template>

                  <template v-slot:cell(active)="data">
                    <b-form-checkbox
                      switch
                      v-model="data.item.active"
                      :name="'' + data.item.id"
                      class="radio-active"
                      size="sm"
                      :value="1"
                      :unchecked-value="0"
                      @input="
                        handleActive(
                          data.item.reference_id != 0
                            ? getIndexByRef(data.item.reference_id)
                            : getIndexById(data.item.id),
                          $event
                        )
                      "
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div class="d-flex align-items-center">
                      <p class="m-0" v-if="data.item.name">
                        <b>
                          {{ data.item.name }}
                        </b>
                      </p>
                      <small
                        class="ml-2"
                        v-if="data.item.random_reward_item_type == 4"
                      >
                        ({{ data.item.point }} Point)
                      </small>

                      <font-awesome-icon
                        v-if="
                          data.item.random_reward_item_type == 4 &&
                          !handleDisabledItems(data.item.reference_id)
                        "
                        icon="pencil-alt"
                        title="Edit Point"
                        class="ml-2 cursor-pointer text-warning"
                        @click="addPoint(data.item, getIndexById(data.item.id))"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant="icon"
                      class="px-0"
                      :disabled="handleDisabledItems(data.item.reference_id)"
                      @click="removeItems(getIndexCondition(data.item))"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                  <template #row-details="row">
                    <b-card>
                      <b-row
                        class="align-items-center justify-content-space-aroud"
                      >
                        <b-col cols="12" sm="4" class="text-left">
                          <div class="main-label">
                            Condition For Receiving The Goods
                            <span class="text-error">*</span>
                          </div>

                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                              :id="
                                'receiving-' +
                                row.item.reference_id +
                                '-' +
                                getIndexByRef(row.item.reference_id) +
                                row.index
                              "
                              v-model="row.item.pick_up_type"
                              :value="row.item.pick_up_type"
                              :aria-describedby="ariaDescribedby"
                              :name="
                                'receiving-' +
                                row.item.reference_id +
                                '-' +
                                getIndexByRef(row.item.reference_id) +
                                row.index +
                                '-slot'
                              "
                            >
                              <b-form-radio
                                :value="0"
                                v-show="row.item.random_reward_item_type != 2"
                                >None
                              </b-form-radio>
                              <b-form-radio
                                :value="1"
                                v-show="row.item.random_reward_item_type == 2"
                                >Pick up at branch
                              </b-form-radio>
                              <b-form-radio
                                :value="2"
                                v-show="row.item.random_reward_item_type == 2"
                                >Pick up at home
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4" class="text-left">
                          <div class="main-label">
                            Availiability <span class="text-error">*</span>
                          </div>

                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                              v-model="row.item.availability"
                              :aria-describedby="ariaDescribedby"
                              :id="
                                'availiability-' +
                                row.item.reference_id +
                                '-' +
                                getIndexByRef(row.item.reference_id) +
                                row.index
                              "
                              :name="
                                'availiability-' +
                                row.item.reference_id +
                                '-' +
                                getIndexByRef(row.item.reference_id) +
                                row.index +
                                '-slot'
                              "
                            >
                              <b-form-radio :value="1">Ready</b-form-radio>
                              <b-form-radio
                                :value="0"
                                v-show="row.item.random_reward_item_type == 2"
                              >
                                Prepare
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-table>

                <!--  -->
                <!-- 
                 
                    </b-table>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-start"
                  >
                    <p class="mb-0 p-gray text-nowrap">
                      Showing
                      <span id="from-row">{{
                        rows == 0
                          ? 0
                          : ((filter.PageNumber - 1) * filter.RecordPerPage + 1)
                            | numeral("0,0")
                      }}</span>
                      to
                      <span id="to-row">
                        {{
                          filter.RecordPerPage * filter.PageNumber > rows
                            ? rows
                            : (filter.RecordPerPage * filter.PageNumber)
                              | numeral("0,0")
                        }}</span
                      >
                      of
                      <span id="total-row">{{ rows | numeral("0,0") }}</span>
                      entries
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-end"
                  >
                    <div>
                      <b-pagination
                        v-model="filter.PageNumber"
                        :total-rows="rows"
                        :per-page="filter.RecordPerPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        class="m-md-0"
                      ></b-pagination>
                    </div>
                    <div class="ml-2">
                      <b-form-select
                        v-model="filter.RecordPerPage"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
                <div v-if="noRewardItems" class="text-error">
                  Please select at least 1 items.
                </div>
                <!-- <div v-if="pleaseSelectProduct"></div> -->
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="random_reward.active"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              random_reward.active ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
      </b-tab>
      <b-tab title="Report">
        <Report ref="Report" @emitActive="emitActive" :form="random_reward" />
      </b-tab>
    </b-tabs>
    <ProductModal
      ref="ProductListModal"
      :form="reward_items"
      :freezeObj="allCurrentItems"
      @submitProduct="submitProduct"
      :disabledItems="isActive"
    />
    <b-modal
      v-model="pointModal"
      @hidden="clearPoint()"
      title="Points Reward"
      hide-header-close
      hide-footer
      centered
    >
      <InputText
        textFloat="Point Reward"
        placeholder="Point Reward"
        type="number"
        v-model="pointDetail.point"
        name="point_reward"
        isRequired
        :v="$v.pointDetail.point"
        :isValidate="$v.pointDetail.point.$error"
      />
      <div class="content-between">
        <b-button variant="custom-outline-primary" @click="pointModal = false"
          >Cancel</b-button
        >
        <b-button variant="custom-primary" @click="handleAddPoint()"
          >Confirm</b-button
        >
      </div>
    </b-modal>
    <FooterAction routePath="/random-reward" @submit="submit" />
  </div>
</template>

<script>
import ProductModal from "./components/Modal";
import Report from "./components/Report";
import PreviewBox from "@/components/PreviewBox.vue";

import { required, minValue, minLength } from "vuelidate/lib/validators";
// import { all } from "tinymce/themes/silver/theme";
export default {
  components: { ProductModal, Report, PreviewBox },
  validations: {
    random_reward: {
      name: { required },
      max_win_rate: { required },
      image_url: { required },
      description: { required },
    },
    reward_items: {
      $each: {
        quantity: {
          required,
        },
        win_rate: {
          required,
        },
      },
    },
    pointDetail: {
      point: {
        required,
      },
    },
  },
  data() {
    return {
      random_reward: {
        id: 0,
        name: "",
        active: 1,
        max_win_rate: 100,
        image_url: "",
        description: "",
      },
      reward_items: [],
      delete_items: [],
      point_reward: 0,
      pointModal: false,
      data: [],
      fields: [
        { key: "id", label: "" },
        { key: "imageUrl", label: "Image" },
        { key: "name", label: "Name / Description", class: "w-4" },
        { key: "quantity", label: "Quantity" },
        { key: "win_rate", label: "Win Rate" },
        { key: "active", label: "Active" },
        { key: "action", label: "" },
      ],
      isBusy: false,
      rows: 0,
      filter: {
        random_reward_id: this.$route.params.id,
        PageNumber: 1,
        RecordPerPage: 5,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      freezeObj: [],
      pointDetail: { point: 0, id: 0, index: 0 },
      noRewardItems: false,
      allCurrentItems: [],
      isActive: false,
      isDisable: false,
      isLoadingImage: false,
    };
  },
  created() {
    if (this.$route.params.id != 0) {
      this.getData();
    }
  },
  methods: {
    addPoint(items, index) {
      this.pointDetail.point = items.point;
      if (items.reference_id) this.pointDetail.id = items.reference_id;
      else {
        this.pointDetail.index = index;
      }

      this.pointModal = true;
    },
    handleAddPoint() {
      this.$v.pointDetail.$touch();

      if (this.$v.pointDetail.$error) return;
      if (this.pointDetail.index) {
        this.reward_items[this.pointDetail.index].point =
          this.pointDetail.point;
      } else {
        let index = this.reward_items.findIndex(
          (el) => el.reference_id == this.pointDetail.id
        );

        this.reward_items[index].point = this.pointDetail.point;
      }
      this.pointModal = false;
    },
    clearPoint() {
      this.pointDetail = { point: 0, id: 0, index: 0 };
    },
    async getData() {
      const resp = await this.axios(`/RandomReward/${this.$route.params.id}`);

      this.random_reward = resp.data.detail.random_reward;
      this.rows = resp.data.detail.random_reward_item.length;
      this.allCurrentItems = resp.data.detail.random_reward_item;

      this.reward_items = resp.data.detail.random_reward_item.map((el) => {
        return {
          ...el,
          name: el.item_name,
          imageUrl: el.item_img,
          _showDetails: true,
        };
      });
    },
    addReward(type) {
      this.reward_items.push({
        id: type == 4 ? `temp-point-${this.reward_items.length}` : 0,
        random_reward_id: 0,
        random_reward_item_type: type,
        reference_id: 0,
        point: 0,
        quantity: 0,
        win_rate: 0,
        pick_up_type: 0,
        availability: 1,
        active: 1,
        item_img: "",
        item_name: type == 1 ? "No Reward Items" : "Point Reward",
        name: type == 1 ? "No Reward Items" : "Point Reward",
        imageUrl: "",
        _showDetails: true,
        sort_order: this.reward_items.length,
      });
      this.rows = this.reward_items.length;
    },

    submitProduct(product) {
      let temp = [
        ...product[0].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: el.random_reward_item_type,
            reference_id: el.reference_id || 0,
            point: el.point || 0,
            quantity: el.quantity || 0,
            win_rate: el.win_rate || 0,
            pick_up_type: 0,
            availability: el.availability || 1,
            active:
              el.active == null ||
              el.active == "" ||
              el.hasOwnProperty("active")
                ? el.active
                : 1,
            _showDetails: true,
          };
        }),
        ...product[1].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: 2,
            reference_id: el.reference_id || 0,
            point: el.point || 0,
            quantity: el.quantity || 0,
            win_rate: el.win_rate || 0,
            pick_up_type: el.pick_up_type || 1,
            availability: el.availability || 1,
            active:
              el.active == null ||
              el.active == "" ||
              el.hasOwnProperty("active")
                ? el.active
                : 1,
            _showDetails: true,
          };
        }),
        ...product[3].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: 3,
            reference_id: el.reference_id || 0,
            point: el.point || 0,
            quantity: el.quantity || 0,
            win_rate: el.win_rate || 0,
            pick_up_type: 0,
            availability: el.availability || 1,
            active:
              el.active == null ||
              el.active == "" ||
              el.hasOwnProperty("active")
                ? el.active
                : 1,
            _showDetails: true,
          };
        }),
      ];
      this.noRewardItems = false;
      this.reward_items = temp.sort((a, b) => a.sort_order - b.sort_order);
      this.rows = temp.length;
    },
    async openProductModal(type) {
      if (this.$refs.Report.rows > 0) this.isActive = true;
      else this.isActive = false;
      this.$refs.ProductListModal.show(1, this.reward_items);
    },
    async submit() {
      let deleteItems = this.allCurrentItems.filter(
        (el) =>
          !this.reward_items.find((e) => e.reference_id == el.reference_id)
      );

      this.$v.random_reward.$touch();
      this.$v.reward_items.$touch();
      this.noRewardItems = false;
      if (this.reward_items.length == 0) return (this.noRewardItems = true);
      if (this.$v.reward_items.$error) return;
      if (this.$v.random_reward.$error) return;
      for (const reward of this.reward_items) {
        if (reward.id != 0)
          if (reward.random_reward_item_type == 4 && reward.id.includes("temp"))
            reward.id = 0;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios.post("/RandomReward", {
        reward_items: this.reward_items,
        random_reward: this.random_reward,
        delete_items: deleteItems,
      });

      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert();
        this.$router.push("/random-reward");
      } else {
        this.errorAlert(res.data.message);
      }
    },
    removeItems(index) {
      this.reward_items.splice(index, 1);
      this.rows = this.reward_items.length;
    },
    handleWinrateInput(val, e, index) {
      var allWinrate = 0;
      var n = 0;
      for (const items of this.reward_items) {
        if (items.active == 1 && n != index) {
          allWinrate += Number(items.win_rate);
        }
        n++;
      }

      if (
        val != "" &&
        allWinrate + Number(val) >= this.random_reward.max_win_rate
      ) {
        this.$nextTick(() => {
          e.target.value =
            this.random_reward.max_win_rate - allWinrate < 0
              ? 0
              : this.random_reward.max_win_rate - allWinrate;
          this.reward_items[index].win_rate =
            this.random_reward.max_win_rate - allWinrate < 0
              ? 0
              : this.random_reward.max_win_rate - allWinrate;
          return e.preventDefault();
        });
      }
    },
    handleActive(index, val) {
      this.$nextTick(() => {
        if (val == 0) this.reward_items[index].win_rate = 0;
      });
    },

    emitActive(rows) {
      if (rows > 0) this.isActive = true;
    },
    handleDisabledItems(id) {
      if (id == 0) return false;
      let find = this.reward_items.find((el) => el.reference_id == id);
      if (find) {
        return this.isActive ? true : false;
      }
      return false;
    },
    getIndexById(id) {
      return this.reward_items.findIndex((el) => el.id == id);
    },
    getIndexByRef(id) {
      return this.reward_items.findIndex((el) => el.reference_id == id);
    },
    getIndexBySort(sort) {
      return this.reward_items.findIndex((el) => el.sort_order == sort);
    },
    getIndexCondition(item) {
      if (item.reference_id) return this.getIndexByRef(item.reference_id);
      else if (item.id == 0) return this.getIndexBySort(item.sort_order);
      return this.getIndexById(item.id);
    },
    tableChange(e, a) {
      this.isBusy = true;
      setTimeout(() => {
        this.isBusy = false;
      }, 500);
    },
    clearWinrate() {
      let temp = [];
      this.isBusy = true;
      for (const items of this.reward_items) {
        items.win_rate = "";

        temp.push(items);
      }
      this.reward_items = JSON.parse(JSON.stringify(temp));
      this.isBusy = false;
    },
    onImageChange(val, key, ratio) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = async () => {
          // if (mode == "icon") {
          if (val.size > 103384000)
            return this.$swal("This file is too large", {
              icon: "info",
            });
          if (ratio == 1) {
            let message = "Please upload image with ratio 1:1 !";
            var width = parseInt(img.width);
            var height = parseInt(img.height);
            if (parseInt(width) !== parseInt(height)) {
              this.$swal(message, {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isLoadingImage = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          } else {
            var width = parseInt(img.width) / 16;
            var height = parseInt(img.height) / 9;

            if (parseInt(width) !== parseInt(height)) {
              this.$swal("Please upload image with ratio 16:9 !", {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isLoadingImage = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          }
          // }

          this.ratioMatch = true;
          this.setUpdataImage(reader.result, key);
        };
        img.src = reader.result;
      };
    },
    async setUpdataImage(base64, key) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);

        this.isLoadingImage = false;
        this.isDisable = false;

        this.random_reward.image_url = url;
        // this.form.banner.is_video = 0;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage(key) {
      this.random_reward.image_url = "";
    },
  },
};
</script>

<style>
.justify-content-space-aroud {
  justify-content: space-around;
}
.point-image {
  background-image: url(../../../assets/img/point.png);
}
.no-reward-image {
  background-image: url(../../../assets/img/Fail.png);
}
</style>
